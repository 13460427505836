import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "donationPeople", "donationOtherRecipient", "donationAmount", "donationRegistration", "donationDateOfRegistration", "donationRegistrationOffice", "donationTaxAmount", "donationNotary", "donationPreview",
    "carBrand", "carModel", "carYear", "carValue", "carPreview",
    "bankCompteType", "bankComptePropriete", "bankComptePourcentage", "bankCompteIban", "bankCompteBanque","bankCompteMontant", "bankPreview",
    "stockQuantity", "stockName", "stockIsin", "stockSingleStockValue", "stockValue", "patrimonyValorisationDate",
    "insuranceType", "insuranceAssureur", "insuranceNumero", "insuranceMontant", "insuranceBeneficiaries", "insurancePreview",
    "immoType", "immoAdresse", "immoValeur", "immoPropriete", "immoPourcentage", "location", "division", "section", "parcel", "partition", "surface", "immoPreview",
    "societeNom", "societeNumero", "societeValeur", "societeParts", "companyPreview",
    "commerceNom", "commerceValeur", "commerceParts", "goodwillPreview",
    "usufructName", "usufructPreview",
    "autreNom", "autreValeur", "otherPreview",
    "passifNom", "passifType", "passifValeur", "passivePreview", "preProrata", "invoiceNumber"
  ]

  connect() {
    const locale = this.element.dataset.locale;
    const preview = this.element.dataset.preview;

    if (locale === 'fr') {
      const action = this[`${preview}Preview`];
      action.call(this);
    }
  }

  donationPreview() {
    const people = Array.from(this.donationPeopleTarget.selectedOptions).map(option => option.text.replace(/\s*\(.*?\)\s*/g, '')).join(', ') || ' ........ '
    const otherRecipient = this.donationOtherRecipientTarget.value
    const amount = this.donationAmountTarget.value || ' ........ '
    const registration = this.donationRegistrationTarget.value || ' ........ '
    const dateOfRegistration = this.donationDateOfRegistrationTarget.value || ' ........ '
    const registrationOffice = this.donationRegistrationOfficeTarget.value || ' ........ '
    const taxAmount = this.donationTaxAmountTarget.value || ' ........ '
    const notary = this.donationNotaryTarget.value || ' ........ '

    let res = `Le défunt / La défunte n'a consenti aucune donation obligatoirement enregistrable dans les trois années qui ont précédé son décès.`
    res += `\n\nA l'exception: \n- Une donation de <b>${this.numberToCurrency(amount)}</b> au bénéfice de <b>${people}${otherRecipient ? `, ${otherRecipient}` : ''}</b>,`

    if (registration === 'without_notary_act') {
      res += ` enregistrée au Bureau Sécurité Juridique de <b>${registrationOffice}</b>, le <b>${dateOfRegistration}</b> dont <b>${this.numberToCurrency(taxAmount)}</b> ont été payés à titre de droits.`
    } else if (registration === 'with_notary_act') {
      res += ` suivant acte reçu par le Notaire <b>${notary}</b>, le <b>${dateOfRegistration}</b> dont <b>${this.numberToCurrency(taxAmount)}</b> ont été payés à titre de droits.`
    } else {
      res += ' non enregistrée.'
      }

    this.donationPreviewTarget.innerHTML = res;
  }

  carPreview() {
    const brand = this.carBrandTarget.value || ' ........ '
    const model = this.carModelTarget.value || ' ........ '
    const year = this.carYearTarget.value || ' ........ '
    const value = this.carValueTarget.value || ' ........ '

    let res = `- La voiture <b>${brand}</b>, <b>${model}</b> de <b>${year}</b> estimée à <b>${this.numberToCurrency(value)}</b>`
    this.carPreviewTarget.innerHTML = res;
  }

  bankPreview() {
    const compteType = this.bankCompteTypeTarget.value
    const comptePropriete = this.bankCompteProprieteTarget.value
    const comptePourcentage = this.bankComptePourcentageTarget.value || ' ........ '
    const compteIban = this.bankCompteIbanTarget.value || ' ........ '
    const compteBanque = this.bankCompteBanqueTarget.value || ' ........ '
    const compteMontant = this.bankCompteMontantTarget.value || ' ........ '
    const stockQuantity = this.stockQuantityTarget.value || ' ........ '
    const stockName = this.stockNameTarget.value || ' ........ '
    const stockIsin = this.stockIsinTarget.value || ' ........ '
    const stockSingleStockValue = this.stockSingleStockValueTarget.value || ' ........ '
    const stockValue = this.stockValueTarget.value || ' ........ '
    const patrimonyValorisationDate = this.patrimonyValorisationDateTarget.value || ' ........ '
    const valorisationDate = compteType === 'stock_account' ? this.formatDate(patrimonyValorisationDate) : ''
    const valorised = compteType === 'stock_account' ? `composé de titres et obligations décrits ci-après et valorisés ensemble suivant leur valeur boursière au ${valorisationDate}, à` : `valorisé à`
    const translations = {
      "current_account" : "un compte à vue",
      "other": "autre",
      "savings_account": "un compte épargne",
      "stock_account": "un compte-titres",
      "usufruct": "usufruit",
      "bare": "nue-propriété",
      "indivision": "indivision",
      "full": "pleine propriété",
    }

    let res = `Les avoirs auprès de <b>${compteBanque}</b>\n`

    if (comptePropriete === 'usufruct') {
      res += `- l’usufruit d'un <b>${translations[compteType]}</b> numéro <b>${compteIban}</b>, <b>${valorised}</b> <b>${this.numberToCurrency(this.patrimonyValue(compteMontant))}</b>\n`
    } else if (comptePourcentage && comptePourcentage > 0 && comptePourcentage != 100) {
      res += `- <b>${comptePourcentage}%</b> d'<b>${translations[compteType]}</b> numéro <b>${compteIban}</b>, <b>${valorised}</b> <b>${this.numberToCurrency(compteMontant * comptePourcentage / 100)}</b>\n`
    } else if (comptePropriete === 'bare' || comptePropriete === 'indivision') {
      res += `- <b>${translations[comptePropriete]}</b> d'<b>${translations[compteType]}</b> numéro <b>${compteIban}</b> <b>${valorised}</b> <b>${this.numberToCurrency(compteMontant)}</b>\n`
    } else if (!comptePropriete || comptePropriete === 'full') {
      res += `- <b>${translations[compteType]}</b> numéro <b>${compteIban}</b> <b>${valorised}</b> <b>${this.numberToCurrency(compteMontant)}</b>\n`
    } else {
      res += `- pour un montant de <b>${(this.patrimonyValue(compteMontant))}</b>\n`
    }
    if (compteType === 'stock_account') {
      res += `<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <b>${this.numberWithDelimiter(stockQuantity)}</b> titres <b>${stockName}</b> <b>(${stockIsin})</b>, dont le cours est de <b>${this.numberToCurrency(stockSingleStockValue)}</b> par titre, soit à déclarer <b>${this.numberToCurrency(stockValue)}</b>;\n`
    }

    this.bankPreviewTarget.innerHTML = res;
  }

  insurancePreview() {
    const insuranceType = this.insuranceTypeTarget.value;
    const insuranceAssureur = this.insuranceAssureurTarget.value || ' ........ ';
    const insuranceNumero = this.insuranceNumeroTarget.value || ' ........ ';
    const insuranceMontant = this.insuranceMontantTarget.value || ' ........ ';
    const beneficiaries = Array.from(this.insuranceBeneficiariesTargets).map(input => input.value || ' ........ ');
    const translations = {
      "death" : "une assurance décès/obsèques",
      "life" : "une assurance-vie"
    }
    const result = insuranceType ? translations[insuranceType] : ' ........ ';

    let res = '';
    res += `Police d’<b>${result}</b> : le défunt/la défunte avait souscrit <b>${result}</b> auprès de la compagnie <b>${insuranceAssureur}</b> selon la police numéro <b>${insuranceNumero}</b> dont le bénéfice net de <b>${this.numberToCurrency(insuranceMontant)}</b> sera liquidée comme suit : `;

    const amountPerBeneficiary = parseFloat(insuranceMontant) / (beneficiaries.length);
    beneficiaries.forEach(beneficiary => {
      res += `\n<b>${this.numberToCurrency(amountPerBeneficiary)}</b> seront liquidés au profit de <b>${beneficiary}</b> ;\n`;
    });

    this.insurancePreviewTarget.innerHTML = res;
  }

  immoPreview() {
    const immoType = this.immoTypeTarget.value;
    const immoAdresse = this.immoAdresseTarget.value || ' ........ ';
    const immoValeur = this.immoValeurTarget.value || ' ........ ';
    const immoPropriete = this.immoProprieteTarget.value;
    const immoPourcentage = this.immoPourcentageTarget.value || ' ........ ';
    const location = this.locationTarget.value || ' ........ ';
    const division = this.divisionTarget.value || ' ........ ';
    const section = this.sectionTarget.value || ' ........ ';
    const parcel = this.parcelTarget.value || ' ........ ';
    const partition = this.partitionTarget.value || ' ........ ';
    const surface = this.surfaceTarget.value || ' ........ ';
    const translations = {
      "usufruct": "usufruit",
      "full": "pleine propriété",
      "bare_ownership": "nue-propriété",
      "apartment": "un appartement",
      "appartement_building": "un immeuble à appartements",
      "commercial_building": "un immeuble à usage commercial",
      "forest": "une forêt",
      "garage": "un garage",
      "house": "une maison",
      "land": "un terrain",
      "other": "autre",
      "parking": "un parking"
    };
    const translatedImmoType = immoType ? translations[immoType] : ' ........ ';
    const translatedImmoPropriete = immoPropriete ? translations[immoPropriete] : ' ........ ';
    const feminineTypes = ['forest', 'house'];
    const surfaceUnits = this.parseLandUnits(surface);

    let res = '';
    res += `\n\n<span class='text-underline'>COMMUNE DE ${location.toUpperCase()} - ${division}</span>\n\n`;
    res += `<br>${immoPourcentage}% en <b>${translatedImmoPropriete}</b> d'<b>${translatedImmoType}</b> ${feminineTypes.includes(immoType) ? 'sise' : 'sis'} <b>${immoAdresse}</b> (${division}), ${feminineTypes.includes(immoType) ? 'cadastrée' : 'cadastré'} selon extrait cadastral récent section <b>${section}</b> numéro <b>${parcel}</b> ${partition}, pour une superficie de <b>${surfaceUnits.hectare}</b> hectares <b>${surfaceUnits.are}</b> ares <b>${surfaceUnits.centiare}</b> centiares, ${feminineTypes.includes(immoType) ? 'estimée' : 'estimé'} à une valeur totale de <b>${this.numberToCurrency(immoValeur)}</b>.`;
    if (immoPourcentage !== ' ........ ' && parseFloat(immoPourcentage) < 100) {
      res += ` Soit pour <b>${immoPourcentage}%</b>, une valeur de <b>${this.numberToCurrency(parseFloat(immoValeur) * parseFloat(immoPourcentage) / 100)}</b> \n`;
    };

    this.immoPreviewTarget.innerHTML = res;
  }

  companyPreview() {
    const societeNom = this.societeNomTarget.value || ' ........ ';
    const societeNumero = this.societeNumeroTarget.value || ' ........ ';
    const societeValeur = this.societeValeurTarget.value || ' ........ ';
    const societeParts = this.societePartsTarget.value || ' ........ ';

    let res = ` - La société <b>${societeNom}</b>, numéro d'entreprise <b>${societeNumero}</b>, à savoir <b>${societeParts}</b> parts estimées à <b>${this.numberToCurrency(societeValeur)}</b>`;
    this.companyPreviewTarget.innerHTML = res
  }

  goodwillPreview() {
    const commerceNom = this.commerceNomTarget.value || ' ........ ';
    const commerceValeur = this.commerceValeurTarget.value || ' ........ ';
    const commerceParts = this.commercePartsTarget.value || ' ........ ';

    let res = ` - <b>${commerceParts}</b> pourcent de la valeur du fonds de commerce <b>${commerceNom}</b>, estimé à <b>${this.numberToCurrency(commerceValeur)}</b>`;
    this.goodwillPreviewTarget.innerHTML = res
  }

  usufructPreview() {
    const usufructName = this.usufructNameTarget.value || ' ........ ';

    let res = ` La succession n'a pas donné lieu à dévolution de fidéicommis, ni à cessation d'usufruit.`;
    res += ` - Par contre, l’usufruit que le défunt/la défunte exerçait à XX % sur <b>${usufructName}</b> s’est éteint au profit ⚠️ du/des nus-propriétaires.`;
    this.usufructPreviewTarget.innerHTML = res
  }

  otherPreview() {
    const autreNom = this.autreNomTarget.value || ' ........ ';
    const autreValeur = this.autreValeurTarget.value || ' ........ ';

    let res = ` - <b>${autreNom}</b> estimé à <b>${this.numberToCurrency(autreValeur)}</b>`;
    this.otherPreviewTarget.innerHTML = res;
  }

  passivePreview() {
    const passifNom = this.passifNomTarget.value || ' ........ ';
    const passifType = this.passifTypeTarget.value || ' ........ ';
    const preProrata = this.preProrataTarget.value || ' ........ ';
    const passifValeur = this.passifValeurTarget.value || ' ........ ';
    const typesRequiringProrata = ['invoice', 'credit', 'property_tax', 'taxe', 'other'];
    const invoiceNumber = this.invoiceNumberTarget.value || ' ........ ';

    let res = `-<b>${passifNom}</b> - `;

    if (passifType === 'invoice') {
      res += `Facture numéro <b>${invoiceNumber}</b> `;
    } else if (passifType === 'funeral') {
      res += `Frais de funérailles `;
    };

    res += `pour un montant de `;

    if (typesRequiringProrata.includes(passifType)) {
      res += `<b>${this.numberToCurrency(preProrata)}</b> au prorata, soit à déclarer <b>${this.numberToCurrency(passifValeur)}</b>\n`;
    } else {
      res += `<b>${this.numberToCurrency(passifValeur)}</b>\n`;
    }

    this.passivePreviewTarget.innerHTML = res;
  }

  numberWithDelimiter(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  }

  numberToCurrency(value) {
    const amount = parseFloat(value)
    if (isNaN(amount)) return value
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)
  }

  patrimonyValue(data) {
    let value = parseFloat(data);
    if (data['immo_pourcentage']) {
      value *= parseFloat(data['immo_pourcentage']) / 100;
    }
    if (data['compte_pourcentage']) {
      value *= parseFloat(data['compte_pourcentage']) / 100;
    }
    return value.toFixed(2);
  }

  formatDate(date) {
    if (!date) return '';

    const dateObj = new Date(date);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    let formattedDate = dateObj.toLocaleDateString('fr-FR', options);

    if (dateObj.getDate() === 1) {
      formattedDate = formattedDate.replace('1 ', '1er ');
    }

    return formattedDate;
  }

  parseLandUnits(surface) {
    if (!surface) return { hectare: 0, are: 0, centiare: 0 };

    const parts = surface.split(' ').map(part => part.replace(/[^\d]/g, '')).map(Number);
    return {
      hectare: parts[0] || 0,
      are: parts[1] || 0,
      centiare: parts[2] || 0
    };
  }

  }
