import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chatFrame", "messageContent"]

  connect() {
    this.messageContentTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.submitMessage();
      }
    });
  }

  toggle() {
    const chatFrame = document.getElementById("chat_frame");
    chatFrame.style.display = chatFrame.style.display === 'none' ? 'flex' : 'none';
    if (!chatFrame.src) {
      chatFrame.src = "/back_office/chats";
    }
  }

  submitMessage() {
    const form = this.messageContentTarget.closest('form');
    form.requestSubmit();
    form.reset();
  }
}