import { Controller } from '@hotwired/stimulus'

function cleanString(string) {
    return string
        .trim()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase()
        .replace(/\W/g, '-');
}

export default class extends Controller {
    // define our targets in our controller
    static targets = ["brand", "model", "year", "link"];

    update() {
        let link = 'https://www.autoscout24.be/fr/lst';

        if (this.brandTarget.value) {
            link += `/${cleanString(this.brandTarget.value)}`;

            if (this.modelTarget.value) link += `/${cleanString(this.modelTarget.value)}`;
        }

        if (this.yearTarget.value) link += `/re_${this.yearTarget.value}`;

        this.linkTarget.href = link;
    }

    connect() {
        this.update();
    }
}
